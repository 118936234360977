import React, { useState } from 'react';
import { connect } from 'react-redux';

import { t } from 'src/translations/i18n';
import { css } from 'emotion';

import { Button, Typography } from '@material-ui/core';

import { MIKE_COLORS } from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors';

import { ROUTES } from 'src/app/routes';

import { theme } from 'src/shared/styles/theme';
import { IGlobalState } from 'store/store';
import { Link } from 'react-router-dom';
import { IUserState } from 'src/store/reducers/UserReducer';

import { MmgMetas } from 'src/shared/metas/metas';

const ErrorPageSectionStyle = css`
  max-width: 500px;
  padding-top: ${theme.spacing(2)}px;
`;

const ErrorPageTitleStyle = css`
  padding-bottom: ${theme.spacing(3)}px;
`;

const ErrorPageRootStyle = css`
  $(ErrorPageSectionStyle);
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;  
`;

const ErrorPagePreStyle = css`
  white-space: pre-wrap;
  color: gray;
  font-size: 0.8rem;
`;

const ErrorPageHelpStyle = css`
  $(ErrorPageSectionStyle);
  margin-top: ${theme.spacing(4)}px;
  padding-top: ${theme.spacing(2)}px;
  border-top: 1px solid ${MIKE_COLORS.MEDIUMGREY_LIGHT};
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT}
`;

const ErrorPageTextColorStyle = css`
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  font-size: 0.875rem;
`;

type IMmgErrorPageProps = {
  userLoggedIn: boolean | null;
  userSessionExpired: boolean;
  applicationError: string;
  applicationErrorTitle: string;
  applicationErrorDesc: string;
  applicationErrorDebugText: string;
  helpExpanded: boolean;
};

/**
 * @name MmgErrorPage
 * @param props
 * @summary Connected error page
 *
 */
const MmgErrorPage = (props: IMmgErrorPageProps) => {
  const {
    userLoggedIn,
    userSessionExpired,
    applicationError,
    applicationErrorTitle,
    applicationErrorDesc,
    applicationErrorDebugText,
  } = props;
  const [helpExpanded, setHelpExpanded] = useState(false);

  const expandHelp = (e) => {
    e.preventDefault();
    setHelpExpanded(true);
  };

  return (
    <>
      <MmgMetas metaTitle={t('GENERIC_ERROR_DESC')} />
      <div className={ErrorPageRootStyle}>
        <Typography className={ErrorPageTitleStyle} variant="h1">
          {applicationErrorTitle ? applicationErrorTitle : t('GENERIC_ERROR_TITLE')}
        </Typography>
        <Typography variant="body2">{applicationErrorDesc ? applicationErrorDesc : t('GENERIC_ERROR_DESC')}</Typography>
        <div className={ErrorPageSectionStyle}>
          {userLoggedIn && !userSessionExpired ? (
            <Link className={ErrorPageTextColorStyle} to={ROUTES.home.path}>
              {t('GO_TO', 1, { thing: t('HOME_PAGE') })}
            </Link>
          ) : (
            <Link className={ErrorPageTextColorStyle} to={ROUTES.home.path}>
              {t('GO_TO', 1, { thing: t('LOGIN_PAGE') })}
            </Link>
          )}
        </div>
        <div className={ErrorPageHelpStyle}>
          {!helpExpanded ? (
            <Button className={ErrorPageTextColorStyle} variant="text" onClick={expandHelp}>
              <Typography variant="body2">{t('GENERIC_ERROR_HELP_TITLE')}</Typography>
            </Button>
          ) : (
            <>
              <Typography variant="body2">{t('GENERIC_ERROR_HELP_DESC')}</Typography>
              <div className={ErrorPagePreStyle}>{applicationError}</div>
              <div className={ErrorPagePreStyle}>{applicationErrorDebugText}</div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapGlobalStateToProps = (state: IGlobalState) => {
  const { userLoggedIn, userSessionExpired } = state.UserReducer as IUserState;
  const { applicationError, applicationErrorTitle, applicationErrorDesc, applicationErrorDebugText } = state.AppReducer;
  return {
    userLoggedIn,
    userSessionExpired,
    applicationError,
    applicationErrorTitle,
    applicationErrorDesc,
    applicationErrorDebugText,
  };
};

export const MmgConnectedErrorPage = connect(mapGlobalStateToProps)(MmgErrorPage);
