import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { css } from 'emotion';
import { useRouteMatch } from 'react-router-dom';

import { MIKE_COLORS } from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors';
import { Breadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { AuditCompareRouteParams } from 'src/app/topbar/topbar';
import { getProjectPath } from 'src/store/actions/project';
import { store } from 'src/store';
import { IWorkspace } from 'src/models/IWorkspaces';

const MmgBreadcrumbsStyle = css`
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  &:hover {
    color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
    border-bottom: ${MIKE_COLORS.BRANDBLUE_DEFAULT} 1px solid;
  }
`;

// set last crumb as bold
const MmgBreadcrumbLastStyle = css`
  font-weight: bold;
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  &:hover {
    color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
    border-bottom: ${MIKE_COLORS.BRANDBLUE_DEFAULT} 2px solid;
  }
  &:focus {
    color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  }
`;

interface ICrumb {
  name: string;
  routePath: string;
}

type IMmgBreadcrumbs = {
  breadcrumbs: Array<ICrumb>;
  workspace: IWorkspace;
};

/**
 * @name MmgBreadcrumbs
 * @param props
 * @summary Breacrumbs for navigation
 *
 */
const MmgBreadcrumbs = (props: IMmgBreadcrumbs) => {
  const { breadcrumbs, workspace } = props;

  const match = useRouteMatch<AuditCompareRouteParams>({
    path: '/project/:id',
    strict: true,
    sensitive: true,
  });
  const projectId = match && match.params.id;

  useEffect(
    () => {
      projectId && store.dispatch(getProjectPath(projectId, workspace));
    },
    [projectId, workspace],
  );

  return breadcrumbs.length > 0 ? (
    <Breadcrumbs maxItems={3} separator={<NavigateNextIcon fontSize="small" />}>
      {breadcrumbs.map((crumb: ICrumb, index: number) => {
        return (
          crumb && (
            <Link
              key={index}
              to={crumb.routePath}
              className={index === breadcrumbs.length - 1 ? MmgBreadcrumbLastStyle : MmgBreadcrumbsStyle}
            >
              {crumb.name}
            </Link>
          )
        );
      })}
    </Breadcrumbs>
  ) : null;
};

const mapGlobalStateToProps = (state) => {
  const { breadcrumbs } = state.AppReducer;
  const { workspace } = state.WorkspaceReducer;
  return { breadcrumbs, workspace };
};

export const MmgConnectedBreadcrumbs = connect(mapGlobalStateToProps)(MmgBreadcrumbs);
