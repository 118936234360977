import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { t } from 'src/translations/i18n';

import { ReactComponent as Delete } from '@mike/mike-shared-frontend/media/icons/Delete';
import { ReactComponent as Edit } from '@mike/mike-shared-frontend/media/icons/Edit';
import { ReactComponent as Plus } from '@mike/mike-shared-frontend/media/icons/Plus';
import { ReactComponent as Adjust } from '@mike/mike-shared-frontend/media/icons/Adjust';

import Tab from '@material-ui/core/Tab';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Tabs } from '@material-ui/core';
import { EItemType } from 'src/models/IOperationDescriptions';
import { EGeometryItemTypes } from 'src/models/IGeometries';
const useAppNavStyles = makeStyles((theme) =>
  createStyles({
    tabsIndicator: {
      height: 3,
    },
    hiddenTabRoot: {
      minWidth: 0,
      padding: 0,
      margin: 0,
      minHeight: 48,
    },
    activeLabel: {
      fontWeight: 'bold',
      color: theme.palette.secondary.main,
    },
    svg: {
      filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(175deg)',
    },
    container: { paddingBottom: theme.spacing(2) },
  }),
);

export enum EEditModeIds {
  EDIT_MODE_MODIFY = 'EDIT_MODE_MODIFY',
  EDIT_MODE_ATTRIBUTION = 'EDIT_MODE_ATTRIBUTION',
  EDIT_MODE_ADD = 'EDIT_MODE_ADD',
  EDIT_MODE_DELETE = 'EDIT_MODE_DELETE',
}

export const EDITMODE_IDS = {
  EDIT_MODE_MODIFY: EEditModeIds.EDIT_MODE_MODIFY,
  EDIT_MODE_ADD: EEditModeIds.EDIT_MODE_ADD,
  EDIT_MODE_DELETE: EEditModeIds.EDIT_MODE_DELETE,
  EDIT_MODE_ATTRIBUTION: EEditModeIds.EDIT_MODE_ATTRIBUTION,
};

interface IProps {
  onEditModeChanged: (mode: EEditModeIds) => void;
  editMode: EEditModeIds;
  itemType: EItemType;
  createNew: boolean;
  disabled?: boolean;
  geometryType?: EGeometryItemTypes;
}

/**
 * @param props
 * @name MmgEditModes
 * @summary Allows changing node modes (modify / add / delete)
 */
const MmgEditModes = (props: IProps) => {
  const classes = useAppNavStyles();
  const { onEditModeChanged, editMode, itemType, createNew, disabled, geometryType = EGeometryItemTypes.POINT } = props;

  const handleChangeEditMode = (_event: ChangeEvent<{}>, mode: EEditModeIds) => {
    onEditModeChanged(mode);
  };

  const { modifyLabel, attributionLabel, addLabel, deleteLabel } = useMemo(
    () => {
      const getModifyLabel = (type: EItemType) => {
        switch (type) {
          case EItemType.VARIABLE: {
            return t('VARIABLE_MOVE_POINTS');
          }
          case EItemType.GEOMETRY: {
            return t('GEOMETRY_MODIFY_VERTICES');
          }
          case EItemType.MESH: {
            return t('MESH_MOVE_NODES');
          }
        }
      };
      const getAttributionLabel = (type: EItemType) => {
        switch (type) {
          case EItemType.VARIABLE: {
            return t('VARIABLE_POINTS_ATTRIBUTION');
          }
          case EItemType.GEOMETRY: {
            return t('GEOMETRY_GEOPROCESSING');
          }
          case EItemType.MESH: {
            return t('MESH_NODES_ATTRIBUTION');
          }
        }
      };
      const getGeometryAddLabel = (geometry: EGeometryItemTypes) => {
        switch (geometry) {
          case EGeometryItemTypes.POINT:
          case EGeometryItemTypes.MULTI_POINT: {
            return t('GEOMETRY_ADD_POINTS');
          }
          case EGeometryItemTypes.LINE_STRING:
          case EGeometryItemTypes.MULTI_LINE_STRING: {
            return t('GEOMETRY_ADD_POLYLINES');
          }
          case EGeometryItemTypes.POLYGON:
          case EGeometryItemTypes.MULTI_POLYGON: {
            return t('GEOMETRY_ADD_POLYGONS');
          }
        }
      };
      const getGeometryDeleteLabel = (geometry: EGeometryItemTypes) => {
        switch (geometry) {
          case EGeometryItemTypes.POINT:
          case EGeometryItemTypes.MULTI_POINT: {
            return t('GEOMETRY_DELETE_POINTS');
          }
          case EGeometryItemTypes.LINE_STRING:
          case EGeometryItemTypes.MULTI_LINE_STRING: {
            return t('GEOMETRY_DELETE_POLYLINES');
          }
          case EGeometryItemTypes.POLYGON:
          case EGeometryItemTypes.MULTI_POLYGON: {
            return t('GEOMETRY_DELETE_POLYGONS');
          }
        }
      };
      const getAddLabel = (type: EItemType, geoType: EGeometryItemTypes) => {
        switch (type) {
          case EItemType.VARIABLE: {
            return t('VARIABLE_ADD_POINTS');
          }
          case EItemType.GEOMETRY: {
            return getGeometryAddLabel(geoType);
          }
          case EItemType.MESH: {
            return t('MESH_ADD_NODES');
          }
        }
      };
      const getDeleteLabel = (type: EItemType, geoType: EGeometryItemTypes) => {
        switch (type) {
          case EItemType.VARIABLE: {
            return t('VARIABLE_DELETE_POINTS');
          }
          case EItemType.GEOMETRY: {
            return getGeometryDeleteLabel(geoType);
          }
          case EItemType.MESH: {
            return t('MESH_DELETE_NODES');
          }
        }
      };
      return {
        modifyLabel: getModifyLabel(itemType),
        attributionLabel: getAttributionLabel(itemType),
        addLabel: getAddLabel(itemType, geometryType),
        deleteLabel: getDeleteLabel(itemType, geometryType),
      };
    },
    [itemType, geometryType],
  );

  return (
    <div className={classes.container}>
      <Tabs
        classes={{ indicator: classes.tabsIndicator }}
        value={editMode}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeEditMode}
        disabled={disabled}
      >
        <Tab classes={{ root: classes.hiddenTabRoot }} />

        {!createNew && <Tab icon={<Edit />} label={modifyLabel} value={EDITMODE_IDS.EDIT_MODE_MODIFY} />}
        {!createNew &&
          itemType !== EItemType.MESH && (
            <Tab icon={<Adjust />} label={attributionLabel} value={EDITMODE_IDS.EDIT_MODE_ATTRIBUTION} />
          )}
        {<Tab icon={<Plus />} label={addLabel} value={EDITMODE_IDS.EDIT_MODE_ADD} />}
        {!createNew && <Tab icon={<Delete />} label={deleteLabel} value={EDITMODE_IDS.EDIT_MODE_DELETE} />}
      </Tabs>
    </div>
  );

  /*   return (
    <div className={classes.container}>
      <Tabs
        classes={{ indicator: classes.tabsIndicator }}
        value={editMode}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeEditMode}
        disabled={disabled}
      >
        <Tab classes={{ root: classes.hiddenTabRoot }} />

        {!createNew && <Tab icon={<Edit />} label={modifyLabel} value={EDITMODE_IDS.EDIT_MODE_MODIFY} />}
        {!createNew &&
          itemType !== EItemType.MESH && (
            <Tab icon={<Adjust />} label={attributionLabel} value={EDITMODE_IDS.EDIT_MODE_ATTRIBUTION} />
          )}
        {itemType !== EItemType.MESH && <Tab icon={<Plus />} label={addLabel} value={EDITMODE_IDS.EDIT_MODE_ADD} />}
        {!createNew &&
          itemType !== EItemType.MESH && (
            <Tab icon={<Delete />} label={deleteLabel} value={EDITMODE_IDS.EDIT_MODE_DELETE} />
          )}
      </Tabs>
    </div>
  ); */
};

export default MmgEditModes;
