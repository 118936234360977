import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { t } from 'src/translations/i18n';
import { css } from 'emotion';

import { Grid, Typography } from '@material-ui/core';

import { MIKE_COLORS } from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors';
import { MmgMetas } from 'src/shared/metas/metas';
import { IProject } from 'models/IProject';

import { ROUTES, getRouteByPath } from 'src/app/routes';
import MmgProjectsTable from 'src/projects/projects-table/projects-table';
import MmgFilterInput from 'src/shared/filter-input/filter-input';
import { MmgConnectedWorkspaceRecents } from 'src/workspaces/recent/workspace-recents';
import MmgConnectedCreateFolder from 'src/workspaces/create/create-folder';

import { theme } from 'src/shared/styles/theme';
import { store } from 'store/store';

const HomePageGridStyle = css`
  background-color: ${MIKE_COLORS.XLIGHTGREY};
  margin: ${theme.spacing(2)}px ${theme.spacing(2)}px 0 ${theme.spacing(3)}px;
`;

const HomePageGridItemStyle = css`
  padding: ${theme.spacing()}px ${theme.spacing()}px 0 ${theme.spacing()}px;
`;

const HomePageGridItemButtonsStyle = css`
  margin-right: 50px;
`;
const HomePageGridWorkspaceStyle = css`
  padding: ${theme.spacing(2)}px ${theme.spacing()}px 0 ${theme.spacing()}px;
`;

type IMmgHomePageProps = {
  projectList: Array<IProject>;
  projectListLoading: boolean;
  projectListLoadingFailed: boolean;
};

const homeCrumb = {
  name: t('HOME'),
  routePath: getRouteByPath(ROUTES.home.path),
};

/**
 * @name MmgHomePage
 * @param props
 * @summary Connected homepage with filterable table and recent workespaces
 *
 */
const MmgHomePage = (props: IMmgHomePageProps) => {
  const { projectList, projectListLoading } = props;
  const [filter, setFilter] = useState('');

  // on mount load Projects
  useEffect(() => {
    store.dispatch({ type: 'project/LOAD_LIST' });
  }, []);

  // on mount set breadcrumbs to home
  useEffect(() => {
    store.dispatch({
      type: 'app/breadcrumbs/SET',
      breadcrumbs: homeCrumb,
    });
  }, []);

  const handleClearFilter = useCallback(() => {
    setFilter('');
    store.dispatch({
      type: 'app/filter/SET',
      filter: '',
    });
  }, []);

  const handleSetFilter = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
    store.dispatch({
      type: 'app/filter/SET',
      filter: e.target.value,
    });
  }, []);

  return (
    <>
      <MmgMetas metaTitle={t('HOME_PAGE_SUBTITLE')} />
      <Grid container className={HomePageGridStyle}>
        <Grid item xs={6} className={HomePageGridItemStyle}>
          <Typography variant="h1">{t('HOME_PAGE_SUBTITLE')}</Typography>
        </Grid>
        <Grid item xs={6} className={HomePageGridItemButtonsStyle}>
          <div className={HomePageGridItemButtonsStyle}>
            <MmgConnectedCreateFolder />
          </div>
        </Grid>
        <Grid item xs={12} className={HomePageGridItemStyle}>
          <MmgFilterInput
            value={filter}
            onChangeValue={handleSetFilter}
            onResetValue={handleClearFilter}
            data-testid="mmg-projects-filter"
          />
        </Grid>
        <Grid item xs={12} className={HomePageGridWorkspaceStyle}>
          <MmgConnectedWorkspaceRecents />
        </Grid>
      </Grid>
      <MmgProjectsTable
        filter={filter}
        projectList={projectList}
        projectListLoading={projectListLoading}
        data-testid="mmg-projects-list"
      />
    </>
  );
};

const mapGlobalStateToProps = (state) => {
  const { projectList, projectListLoading, projectListLoadingFailed } = state.ProjectReducer;

  return { projectList, projectListLoading, projectListLoadingFailed };
};

export const MmgConnectedHomePage = connect(mapGlobalStateToProps)(MmgHomePage);
