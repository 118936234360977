/**
 * Exposes methods to setup, transform or combine project Websocket events.
 *
 * @module WorkspaceSocketManager
 * @version 2.0.0
 * @requires WorkspaceSocketProxy
 */
import { workspaceSocketProxy } from 'src/proxies/WorkspaceSocketProxy';
import { IWorkspace, IWorkspaceOverview } from 'models/IWorkspaces';
import {
  INotificationItemData,
  INotificationGeometryDeletedItems,
  INotificationVariableDeletedItems,
  INotificationMeshDeletedItems,
  INotificationAttributeData,
  INotificationItemDataStructure,
  INotificationItemDataUpdatedMsg,
  INotificationAttributeUpdatedMsg,
  IStreamedAttributeData,
  INotificationQueryDeletedItems,
  IMeshStreamRequestParameter,
  IMeshStreamInfo,
  IBounds,
} from 'models/IWorkspaceNotifications';
import {
  DATA_ARRAY_TYPES,
  EDataArrayTypes,
} from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizerConstants';
import FeatureFlags from 'src/app/feature-flags';
import { IHubConnectionConfiguration } from './ConfigurationManager';
import { IOperationMetadata } from 'models/IOperations';
import { IWorkspaceComment } from 'models/IComments';
import { IWorkspaceGeometry } from 'models/IGeometries';
import { IWorkspaceMesh } from 'models/IMeshes';
import { IWorkspaceVariable } from 'models/IVariables';
import { IWorkspaceQuery } from 'models/IQueries';
import { IExports } from 'models/IExports';
import { IGeojsonCreated } from 'src/models/IGeojsonCreated';
import { FeatCollAny } from 'src/models/IGeometryUtils';
import { ILabelNotification } from 'src/models/ILabelNotification';
import { IQueryCompleted } from 'src/models/IQueryCompleted';

let socketManagerInstance: ReturnType<typeof workspaceSocketManager> | undefined = undefined;

/**
 * Sets up & transforms data for listeners to workspace metadata & data changes.
 * NB: Run only once as `getInstance` returns a single instance of the socketManager,
 * ie the latest instance.
 *
 * @param workspaceId
 * @param sasToken
 * @param hubConnectionConfiguration
 */
export const workspaceSocketManager = (
  workspaceId: string,
  sasToken: string,
  hubConnectionConfiguration?: IHubConnectionConfiguration,
) => {
  const MANAGER_EVENTS = {
    WORKSPACE_CONNECTED: 'workspaceConnected',
    WORKSPACE_DELETED: 'workspaceDeleted',
    WORKSPACE_STRUCTURE_UPDATED: 'workspaceStructureUpdated',
    WORKSPACE_OVERVIEW_UPDATED: 'workspaceOverviewUpdated',

    VTKFILE_CREATED_OR_UPDATED: 'workspaceVtkFileCreatedOrUpdated',
    RAW_DATA_LOADED: 'workspaceRawDataLoaded',
    TILE_DATA_LOADED: 'workspaceTileDataLoaded',
    ALL_TILE_DATA_LOADED: 'workspaceTileAllDataLoaded',
    RAW_DATA_LOADED_EXCL_DATAARRAYS: 'workspaceRawDataLoadedExclDataArrays',
    DATA_DELETED: 'workspaceDataDeleted',
    DATA_ITEMS_DELETED: 'workspaceDataItemsDeleted',

    DATA_ATTRIBUTE_ADDED_OR_UPDATED: 'workspaceDataAttributeAddedOrUpdated',
    DATA_ATTRIBUTE_LOADED: 'workspaceDataAttributeLoaded',
    DATA_ATTRIBUTE_DELETED: 'workspaceDataAttributeDeleted',
    DATA_ATTRIBUTE_FAILED_LOADING: 'workspaceDataAttributeFailed',

    COMMENT_CREATED: 'WorkspaceCommentCreated',
    COMMENT_UPDATED: 'WorkspaceCommentUpdated',
    COMMENT_DELETED: 'WorkspaceCommentDeleted',

    OPERATION_CREATED_OR_UPDATED: 'workspaceOperationCreatedOrUpdated',
    OPERATIONS_DELETED: 'workspaceOperationsDeleted',

    GEOMETRIES_DELETED: 'workspaceGeometriesDeleted',
    VARIABLES_DELETED: 'workspaceVariablesDeleted',
    MESHES_DELETED: 'workspaceMeshesDeleted',

    QUERIES_DELETED: 'queriesDeleted',
    QUERY_COMPLETED: 'queryCompleted',
    VOLATILE_OPERATION_CREATED_OR_UPDATED: 'volatileOperationCreatedOrUpdated',

    ITEM_ADDED_OR_UPDATED: 'workspaceItemAddedOrUpdated',
    ITEM_FAILED_LOADING: 'workspaceItemFailedLoading',
    TILED_ITEM_FAILED_LOADING: 'workspaceTiledItemFailedLoading',

    EXPORT_STARTED_OR_COMPLETED: 'exportStartedOrCompleted',
    SNAPSHOT_OPERATION_CREATED_OR_UPDATED: 'snapshotOperationCreatedOrUpdated',

    LABELS_CREATED: 'labelsCreated',
  };

  const { keepAliveInterval: keepAliveIntervalOnServer, clientTimeoutInterval: clientTimeoutIntervalOnServer } =
    hubConnectionConfiguration || {};

  // The recommended value is a number at least double the server's KeepAliveInterval value to allow time for pings to arrive.
  // see https://docs.microsoft.com/en-us/aspnet/core/signalr/configuration?view=aspnetcore-5.0&tabs=javascript#configure-client-options
  const serverTimeoutInMilliseconds = keepAliveIntervalOnServer ? keepAliveIntervalOnServer * 4 : undefined;

  // If the client hasn't send a message in the server's ClientTimeoutInterval, the server considers the client disconnected.
  // see https://docs.microsoft.com/en-us/aspnet/core/signalr/configuration?view=aspnetcore-5.0&tabs=javascript#configure-client-options
  // we want to make sure we send often enough
  const keepAliveIntervalInMilliseconds = clientTimeoutIntervalOnServer ? clientTimeoutIntervalOnServer / 4 : undefined;

  const workspaceProxy = workspaceSocketProxy(
    workspaceId,
    sasToken,
    serverTimeoutInMilliseconds,
    keepAliveIntervalInMilliseconds,
  );

  const {
    connectionStartPromise,
    emit,
    on,
    off,
    onMessageEvent,
    close,
    defaultEvents,
    events,
    send,
    streamWorkspaceVtkRawFileDefault,
    streamWorkspaceVtkGeometry,
    streamWorkspaceVtkData,
    streamMeshAsync,
  } = workspaceProxy;

  // This requests a full workspace load from the back-end. It will return workspace metadata and each data corresponding to meshes, geometries and variables in the workspace.
  connectionStartPromise.then(() => {
    send('ConnectWorkspace');
  });

  // Stop listening to all known events and close socket.
  const disconnect = () => {
    const allEvents = { ...MANAGER_EVENTS, ...events };
    close(Object.keys(allEvents).map((key) => allEvents[key]));
    socketManagerInstance = undefined;
  };

  // Stream the raw data including data arrays. Emits an event with the provided data, so consumer components can render it.
  const streamRawData = (itemId: string, _dataId: string) => {
    // Stream callback. Emits an event with the provided data, so consumer components can render it.
    const onStreamComplete = (rawDataItem: INotificationItemData) => {
      if (rawDataItem) {
        if (!rawDataItem.data) {
          emit(MANAGER_EVENTS.RAW_DATA_LOADED, { itemId, dataId: _dataId });
        } else {
          emit(MANAGER_EVENTS.RAW_DATA_LOADED, rawDataItem);

          // For debug purposes, a local storage value can be set to output data to the console.
          if (localStorage.getItem('DEBUG_SOCKET_ON') === 'true') {
            const { data, ...logMsg } = rawDataItem;
            console.log('streamRawData completed', 'rawDataItem (data omitted)', logMsg);
          }
        }
      }
    };

    const onError = () => {
      emit(MANAGER_EVENTS.ITEM_FAILED_LOADING, itemId);
    };

    streamWorkspaceVtkRawFileDefault(onStreamComplete, onError, itemId);
  };

  const streamMesh = (
    itemId: string,
    currentBounds: Array<Array<number>>,
    previousBounds: Array<IBounds>,
    ignoreOverviewPolygon: boolean,
  ) => {
    // Stream callback. Emits an event with the provided data, so consumer components can render it.
    const onStreamComplete = (rawDataItem: INotificationItemData, streamInfo: IMeshStreamInfo) => {
      if (rawDataItem) {
        emit(MANAGER_EVENTS.TILE_DATA_LOADED, rawDataItem, streamInfo);
        // For debug purposes, a local storage value can be set to output data to the console.
        if (localStorage.getItem('DEBUG_SOCKET_ON') === 'true') {
          const { data, ...logMsg } = rawDataItem;
          console.log('One mesh tile streamed for ' + itemId, 'rawDataItem (data omitted)', logMsg);
        }
      }
    };

    const onStreamingComplete = (partIds: Array<string>, size: number, streamInfo: IMeshStreamInfo) => {
      emit(MANAGER_EVENTS.ALL_TILE_DATA_LOADED, partIds, size, streamInfo);
      // For debug purposes, a local storage value can be set to output data to the console.
      if (localStorage.getItem('DEBUG_SOCKET_ON') === 'true') {
        console.log('All mesh tiles streamed for ' + streamInfo.itemId);
      }
    };

    const onError = (error: string) => {
      console.log(error);
      emit(MANAGER_EVENTS.TILED_ITEM_FAILED_LOADING, itemId);
    };

    streamMeshAsync(onStreamComplete, onStreamingComplete, onError, {
      itemId,
      ignoreBounds: previousBounds,
      ignoreBorderPolygon: ignoreOverviewPolygon,
      bounds: {
        minX: currentBounds[0][0],
        minY: currentBounds[0][1],
        maxX: currentBounds[2][0],
        maxY: currentBounds[2][1],
      },
    } as IMeshStreamRequestParameter);
  };

  // Stream the raw data excluding data arrays. Emits an event with the provided data, so consumer components can render it.
  const streamRawDataExclDataArrays = (itemId: string, _dataId: string) => {
    if (!FeatureFlags.useStreamVtkGeometry) {
      console.log(
        'Streaming of raw data structure not supported. Turn on by adding TURN_ON_STREAM_VTKGEOMETRY = true to localStorage',
      );
      return;
    }

    // Stream callback. Emits an event with the provided data, so consumer components can render it.
    const onStreamComplete = (rawDataItem: INotificationItemDataStructure) => {
      if (rawDataItem) {
        emit(MANAGER_EVENTS.RAW_DATA_LOADED_EXCL_DATAARRAYS, rawDataItem);

        // For debug purposes, a local storage value can be set to output data to the console.
        if (localStorage.getItem('DEBUG_SOCKET_ON') === 'true') {
          const { data, ...logMsg } = rawDataItem;
          console.log('streamRawDataExclDataArrays completed', 'rawDataItem (data omitted)', logMsg);
        }
      }
    };

    const onError = () => {
      emit(MANAGER_EVENTS.ITEM_FAILED_LOADING, itemId);
    };

    streamWorkspaceVtkGeometry(onStreamComplete, onError, itemId);
  };

  // Stream the vtkFile data. By default including data arrays. Emits an event with the provided data, so consumer components can render it.
  const streamVtkFileData = (itemId: string, dataId = '', excludeDataArrays?: boolean) => {
    if (!excludeDataArrays) {
      streamRawData(itemId, dataId);
    } else {
      streamRawDataExclDataArrays(itemId, dataId);
    }
  };

  // stream cell or point data array for a specific attribute
  const streamAttributeData = (
    itemId: string,
    dataId: string,
    attributeName: string,
    dataArrayType: EDataArrayTypes,
    updated?: string, // todo hevo cn we avoid this, if the api could include it in header chunk?
  ) => {
    // Stream callback. Emits an event with the provided data, so consumer components can render it.
    const onStreamComplete = (streamedData: IStreamedAttributeData) => {
      if (streamedData) {
        const messageData: INotificationAttributeData = {
          itemId,
          dataId,
          attributeName,
          type: dataArrayType,
          data: streamedData.data,
          updated,
        };

        emit(MANAGER_EVENTS.DATA_ATTRIBUTE_LOADED, messageData);

        // For debug purposes, a local storage value can be set to output data to the console.
        if (localStorage.getItem('DEBUG_SOCKET_ON') === 'true') {
          const { data, ...logMsg } = messageData;
          console.log('streamAttributeData completed', 'attribute msg (data omitted)', logMsg);
        }
      }
    };

    const onError = () => {
      emit(MANAGER_EVENTS.DATA_ATTRIBUTE_FAILED_LOADING, itemId, attributeName);
    };

    streamWorkspaceVtkData(
      onStreamComplete,
      onError,
      dataId,
      attributeName,
      dataArrayType === DATA_ARRAY_TYPES.CELLDATA,
    );
  };

  onMessageEvent(events.LABELS_CREATED, (labelsCreatedMsg: string) => {
    const labels: ILabelNotification = JSON.parse(labelsCreatedMsg);
    emit(MANAGER_EVENTS.LABELS_CREATED, labels);
  });

  onMessageEvent(events.QUERY_COMPLETED, (queryCompletedMsg: string) => {
    const queryResult: IQueryCompleted = JSON.parse(queryCompletedMsg);
    emit(MANAGER_EVENTS.QUERY_COMPLETED, queryResult);
  });

  // Handle Export events
  onMessageEvent(events.EXPORT_STARTED_OR_COMPLETED, (exportMsg: string) => {
    const exportMetadata: IExports = JSON.parse(exportMsg);

    emit(MANAGER_EVENTS.EXPORT_STARTED_OR_COMPLETED, exportMetadata);
  });

  //message event for snapshot created
  onMessageEvent(events.SNAPSHOT_OPERATION_CREATED_OR_UPDATED, (workspaceMsg: string) => {
    const snapshotOperation: any = JSON.parse(workspaceMsg);
    emit(MANAGER_EVENTS.OPERATION_CREATED_OR_UPDATED, snapshotOperation);
  });

  // Handle workspace events
  onMessageEvent(events.WORKSPACE, (workspaceMsg: string) => {
    const workspace: IWorkspace = JSON.parse(workspaceMsg);

    emit(MANAGER_EVENTS.WORKSPACE_STRUCTURE_UPDATED, workspace);
  });

  onMessageEvent(events.WORKSPACE_CONNECTED, (workspaceMsg: string) => {
    const workspace: IWorkspace = JSON.parse(workspaceMsg);

    emit(MANAGER_EVENTS.WORKSPACE_CONNECTED, workspace);
  });

  onMessageEvent(events.WORKSPACE_DELETED, (wsId: string) => {
    emit(MANAGER_EVENTS.WORKSPACE_DELETED, wsId);
  });

  // Handle data events
  onMessageEvent(
    events.VTK_FILE_CREATED_OR_UPDATED,
    (workspaceMsg: string, itemMsg: string, itemDataUpdatedMsg?: string) => {
      const workspace: IWorkspaceOverview = JSON.parse(workspaceMsg);

      const item: IWorkspaceGeometry | IWorkspaceMesh | IWorkspaceVariable | IWorkspaceQuery = JSON.parse(itemMsg);

      const parsedItemDataUpdatedMsg: INotificationItemDataUpdatedMsg = JSON.parse(itemDataUpdatedMsg);

      emit(MANAGER_EVENTS.ITEM_ADDED_OR_UPDATED, item);

      if (parsedItemDataUpdatedMsg) {
        if (item.itemType === 'Mesh') {
          const meshItem = item as IWorkspaceMesh;
          emit(MANAGER_EVENTS.VTKFILE_CREATED_OR_UPDATED, { ...parsedItemDataUpdatedMsg, isTiled: meshItem.isTiled });
        } else {
          // Emit vtkfile created updated.
          emit(MANAGER_EVENTS.VTKFILE_CREATED_OR_UPDATED, parsedItemDataUpdatedMsg);
        }
      }
      if (workspace) {
        emit(MANAGER_EVENTS.WORKSPACE_OVERVIEW_UPDATED, workspace);
      }

      // For debug purposes, a local storage value can be set to output data to the console.
      if (localStorage.getItem('DEBUG_SOCKET_ON') === 'true') {
        console.log(events.VTKFILE_CREATED_OR_UPDATED, 'item', item);
        console.log(events.VTKFILE_CREATED_OR_UPDATED, 'parsedVtkDataUpdatedMsg', parsedItemDataUpdatedMsg);
      }
    },
  );

  onMessageEvent(
    events.VTK_FILE_DATAARRAY_ADDED_OR_UPDATED,
    (_workspaceMsg: string, itemMsg: string, attributeUpdatedMsg: string) => {
      const item: IWorkspaceGeometry | IWorkspaceMesh | IWorkspaceVariable = JSON.parse(itemMsg);

      const parsedAttributeUpdatedMsg: INotificationAttributeUpdatedMsg = JSON.parse(attributeUpdatedMsg);

      // Emit item updated.
      emit(MANAGER_EVENTS.ITEM_ADDED_OR_UPDATED, item);

      if (parsedAttributeUpdatedMsg) {
        // Emit vtkfile created updated.
        parsedAttributeUpdatedMsg.itemType = item.itemType; // todo hevo should be added in backend instead!

        emit(MANAGER_EVENTS.DATA_ATTRIBUTE_ADDED_OR_UPDATED, parsedAttributeUpdatedMsg);
      }

      // For debug purposes, a local storage value can be set to output data to the console.
      if (localStorage.getItem('DEBUG_SOCKET_ON') === 'true') {
        console.log(events.VTK_FILE_DATAARRAY_ADDED_OR_UPDATED, 'parsedAttributeUpdatedMsg', parsedAttributeUpdatedMsg);
        console.log(events.VTK_FILE_DATAARRAY_ADDED_OR_UPDATED, 'item', item);
      }
    },
  );

  onMessageEvent(
    events.VTK_FILE_DATAARRAY_DELETED,
    (_workspaceMsg: string, itemMsg: string, dataArrayUpdatedMsg: string) => {
      const item: IWorkspaceGeometry | IWorkspaceMesh | IWorkspaceVariable = JSON.parse(itemMsg);

      const parsedDataArrayUpdatedMsg: INotificationAttributeUpdatedMsg = JSON.parse(dataArrayUpdatedMsg);

      // Emit item updated.
      emit(MANAGER_EVENTS.ITEM_ADDED_OR_UPDATED, item);

      emit(MANAGER_EVENTS.DATA_ATTRIBUTE_DELETED, parsedDataArrayUpdatedMsg);
    },
  );

  // Handle comments events
  onMessageEvent(events.COMMENT_CREATED, (_workspaceId: string, commentMsg: string) => {
    const comment: IWorkspaceComment = JSON.parse(commentMsg);

    emit(MANAGER_EVENTS.COMMENT_CREATED, comment);
  });

  onMessageEvent(events.COMMENT_DELETED, (_workspaceId: string, commentId: string) => {
    emit(MANAGER_EVENTS.COMMENT_DELETED, commentId);
  });

  // Handle Operation events
  onMessageEvent(events.OPERATION_CREATED_OR_UPDATED, (operationMsg: string) => {
    const operationMetadata: IOperationMetadata = JSON.parse(operationMsg);
    console.log('OPERATION MESSAGES', operationMetadata);
    emit(MANAGER_EVENTS.OPERATION_CREATED_OR_UPDATED, operationMetadata);
  });

  // Handle volatile operation events
  onMessageEvent(events.VOLATILE_OPERATION_CREATED_OR_UPDATED, (operationMsg: string) => {
    const operationMetadata: IOperationMetadata = JSON.parse(operationMsg);
    console.log('OPERATION MESSAGES', operationMetadata);
    emit(MANAGER_EVENTS.VOLATILE_OPERATION_CREATED_OR_UPDATED, operationMetadata);
  });

  onMessageEvent(events.OPERATIONS_DELETED, (_workspaceId: string, operationIdsMsg: string) => {
    const operationIds: Array<string> = JSON.parse(operationIdsMsg);
    emit(MANAGER_EVENTS.OPERATIONS_DELETED, operationIds);
  });

  // Handle geometry events

  onMessageEvent(events.GEOMETRIES_DELETED, (workspaceMsg: string, affectedItemsMsg) => {
    const {
      geometryIds = [],
      // geometryDataIds = [], // not needed, deleting the geometry will also delete data from viewer
      operationIds = [],
    }: INotificationGeometryDeletedItems = JSON.parse(affectedItemsMsg) || {};
    emit(MANAGER_EVENTS.GEOMETRIES_DELETED, geometryIds, operationIds);
    emitWorkspaceOverviewUpdated(workspaceMsg);
  });

  // Handle variable events
  onMessageEvent(events.VARIABLES_DELETED, (workspaceMsg: string, affectedItemsMsg: string) => {
    const {
      variableIds = [],
      //variableDataIds=[], // not needed, deleting the variable will also delete data from viewer
      operationIds = [],
    }: INotificationVariableDeletedItems = JSON.parse(affectedItemsMsg) || {};
    emit(MANAGER_EVENTS.VARIABLES_DELETED, variableIds, operationIds);
    emitWorkspaceOverviewUpdated(workspaceMsg);
  });

  // Handle mesh events
  onMessageEvent(events.MESHES_DELETED, (workspaceMsg: string, affectedItemsMsg: string) => {
    const {
      meshIds = [],
      // meshDataIds = [], // not needed, deleting the mesh will also delete data from viewer
      operationIds = [],
    }: INotificationMeshDeletedItems = JSON.parse(affectedItemsMsg) || {};
    emit(MANAGER_EVENTS.MESHES_DELETED, meshIds, operationIds);
    emitWorkspaceOverviewUpdated(workspaceMsg);
  });

  // Handle query events
  onMessageEvent(events.QUERIES_DELETED, (workspaceMsg: string, affectedItemsMsg: string) => {
    const { queryIds = [] }: INotificationQueryDeletedItems = JSON.parse(affectedItemsMsg) || {};
    emit(MANAGER_EVENTS.QUERIES_DELETED, queryIds);
    emitWorkspaceOverviewUpdated(workspaceMsg);
  });

  const emitWorkspaceOverviewUpdated = (workspaceMsg: string) => {
    const workspaceOverview: IWorkspaceOverview = JSON.parse(workspaceMsg);
    if (workspaceOverview) {
      emit(MANAGER_EVENTS.WORKSPACE_OVERVIEW_UPDATED, workspaceOverview);
    }
  };

  const instance = {
    on,
    off,
    defaultEvents,
    managerEvents: MANAGER_EVENTS,
    disconnect,
    streamRawData, // todo hevo should not be exposed ??
    streamRawDataExclDataArrays, // todo hevo should not be exposed ??
    streamVtkFileData,
    streamAttributeData,
    streamMesh,
  };
  if (socketManagerInstance !== undefined) {
    console.error(
      `Now overwriting single instance of 'socketManagerInstance'. Don't initiate 'workspaceSocketManager' more than once if you want 'getInstance' to work as intended.`,
    );
  }
  socketManagerInstance = instance;
  return instance;
};

/**
 *
 * @returns An instance of the workspaceManager.
 */
export const getInstance = () => {
  return socketManagerInstance;
};

const self = {
  workspaceSocketManager,
  getInstance,
};

export default self;
