import { useEffect } from 'react';
import { connect } from 'react-redux';

import MikeVisualizer from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizer';
const { setEpsgCode } = MikeVisualizer;

type ViewerProjectionProps = {
  epsgCode: number;
};

/**
 * @name MmgViewerProjection
 * @summary Sets the viewer projection based on the workspace epsg code.
 *
 * @param props
 */
export const MmgViewerProjection = (props: ViewerProjectionProps) => {
  const { epsgCode } = props;

  useEffect(
    () => {
      setEpsgCode(epsgCode);
    },
    [epsgCode],
  );

  return null;
};

const mapGlobalStateToProps = (state) => {
  const { workspace } = state.WorkspaceReducer;

  return {
    epsgCode: workspace ? workspace.epsgCode : null,
  };
};

export const MmgConnectedViewerProjection = connect(mapGlobalStateToProps)(MmgViewerProjection);
