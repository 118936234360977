import React from 'react';
import { connect } from 'react-redux';

import MikeSnackbar from '@mike/mike-shared-frontend/mike-snackbar';
import { store } from 'src/store';

const MmgSnackBar = ({ toasts }) => {
  const onRemoveSnackbar = (id) => {
    store.dispatch({ type: 'toast/DELETE', toastId: id });
  };

  const toast = toasts && toasts.length > 0 && toasts[toasts.length - 1];
  if (!toast) {
    return null;
  }

  return (
    <MikeSnackbar
      action={'OK'}
      open={toast ? true : false}
      mikeSnackbarType={toast.type}
      autoHideDuration={5000}
      onClose={() => {
        onRemoveSnackbar(toast.id);
      }}
      id={toast.id}
      mikeOnAction={() => {
        onRemoveSnackbar(toast.id);
      }}
    >
      {toast.text}
    </MikeSnackbar>
  );
};

const mapStateToProps = (state) => {
  const { toasts } = state.ToastReducer;
  return {
    toasts,
  };
};

export const MmgConnectedSnackbar = connect(mapStateToProps)(MmgSnackBar);
